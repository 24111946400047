import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import LegalInformationPage from '../ui/pages/LegalInformationPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Page = props => (
  <>
    <HelmetComponent
      description={metaTags.dataprivacy}
      page="de/data-privacy"
    />
    <AppWrapper {...props} lang="de">
      <LegalInformationPage />
    </AppWrapper>
  </>
)

export default Page
